.App {
    color: white;
    background-color: #282c34;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-header {
    color: white;
    background-color: #282c34;
    min-height: 8vh;
    max-height: 8vh;
    min-width: 100vw;
    max-width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-body {
    color: white;
    background-color: #282c34;
    min-height: 84vh;
    max-height: 84vh;
    min-width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.App-footer {
    color: white;
    background-color: #282c34;
    min-height: 8vh;
    max-height: 8vh;
    min-width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}
